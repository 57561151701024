import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
// eslint-disable-next-line import/no-cycle
import { SingleDish } from '../pages/categoryList';

const Container = styled(Link)`
  width: 90%;
  border-radius: 10px;
  margin: 5px;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 120px;
  box-shadow: 0px 2px 5px gray;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.brown};
  scroll-margin: 360px;
  background-color: ${({ theme }) => theme.colors.olive};
  :nth-child(2) {
    margin-top: 30px;
  }
  ${({ theme }) => theme.media.tablet} {
    width: 80%;
    :nth-child(2) {
      margin-top: 50px;
    }
  }
`;

const ContentContainer = styled.div`
  padding-top: 15px;
  min-height: 120px;
  display: flex;
  flex-flow: column wrap;
  ${({ theme }) => theme.media.tablet} {
  }
`;

const Title = styled.h2`
  margin: 5px;
  font-size: 1.7rem;
  text-align: center;
  ${({ theme }) => theme.media.tablet} {
    font-size: 2.5rem;
  }
`;
const Price = styled.span`
  text-align: center;
  font-size: 1.8rem;
  ${({ theme }) => theme.media.tablet} {
    font-size: 2.2rem;
  }
`;

// const Image = styled.img`
//   width: 50%;
//   border-radius: 15px;
//   margin: 5px;
//   ${({ theme }) => theme.media.tablet} {
//     width: 35%;
//   }
// `;
const DishList = ({ node, id }: SingleDish) => {
  const { category, title, slug, price } = node;
  return (
    <Container
      data-sal="zoom-out"
      data-sal-duration="400"
      data-sal-easing="ease"
      to={`/menu/${category}/${slug}`}
      id={id}
    >
      <ContentContainer>
        <Title>{title}</Title>
        <Price>{price}zł</Price>
      </ContentContainer>
      {/* {heroImage ? <Image src={heroImage.file.url} /> : ''} */}
    </Container>
  );
};
export default DishList;
