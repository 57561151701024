import { graphql, Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-cycle
import DishList from '../components/DishList';
import Layout from '../components/Layout/Layout';
import ScrollHandler from '../components/ScrollHandler';

const Button = styled.button`
  align-self: flex-end;
  min-width: 120px;
  margin: 20px;
  padding: 10px;
  border: 0;
  font-family: 'Oregano';
  font-size: 1.4rem;
  background-color: ${({ theme }) => theme.colors.button};
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.media.desktop} {
    font-size: 2rem;
    padding: 10px 15px;
  }
`;

const Header = styled.h1`
  text-align: center;
  font-size: 1.5rem;
  margin: 20px;
  width: 80%;
  ${({ theme }) => theme.media.tablet} {
    font-size: 2.5rem;
  }
`;

export type SingleDish = {
  id: string;
  node: {
    title: string;
    // heroImage: {
    //   file: {
    //     url: string;
    //   };
    // };
    description: {
      description: string;
    };
    weight: string;
    price: string;
    slug: string;
    category: string;
    label?: string;
  };
};

export type Props = {
  location?: any;
  label?: string;
  data: {
    allContentfulImages: any;
    allContentfulDishes: {
      edges: SingleDish[];
    };
  };
};

const CategoryPage = ({ data, location }: Props) => {
  const { edges } = data.allContentfulDishes;
  const logo = data.allContentfulImages.edges[0].node.logo.file.url;
  const backgroundDesktop =
    data.allContentfulImages.edges[0].node.backgrounds[0].file.url;

  const [isSticky, setSticky] = useState(false);
  useEffect(() => {
    const changeSize = () => {
      if (window.scrollY >= 120) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
    window.addEventListener('scroll', changeSize);
    return () => {
      window.removeEventListener('scroll', changeSize);
    };
  }, []);

  const renderDefaultMessage = () => {
    if (location.pathname === `/menu/przekaski`) {
      return (
        <Header>
          Dania te domyślnie serwowane są z surówką i sosem słodko kwaśnym
        </Header>
      );
    }
    if (
      location.pathname === `/menu/dania-z-ryzem` ||
      location.pathname === `/menu/dania-z-makaronem`
    ) {
      return <Header>Dania te domyślnie serwowane są z surówką</Header>;
    }
    if (
      location.pathname === `/menu/desery` ||
      location.pathname === `/menu/zupy` ||
      location.pathname === `/menu/nowosci` ||
      location.pathname === `/menu/dania-wegetarianskie`
    ) {
      return null;
    }
    return (
      <Header>Dania te domyślnie serwowane są z ryżem białym i surówką</Header>
    );
  };

  return (
    <Layout
      title="Kategorie"
      logo={logo}
      backgroundDesktop={backgroundDesktop}
      description="Sprawdź jakie mamy dania!"
      ogTitle="Menu | Smaki Azji Siemianowice Śląskie"
      ogContent="Smaki Azji w Siemianowicach Śląskich, zamów jedzenie na wynos, lub przyjedź i spróbuj naszych pysznych dań na miejscu!"
    >
      <ScrollHandler isSticky={isSticky} />
      {renderDefaultMessage()}
      {edges &&
        // eslint-disable-next-line array-callback-return
        // filter in the future?
        edges.map((edge: SingleDish, key) => {
          if (`/menu/${edge.node.category}` === location.pathname) {
            return <DishList key={key} id={edge.node.slug} node={edge.node} />;
          }

          return null;
        })}
      <Link to="/menu">
        <Button type="button">Wróć do menu</Button>
      </Link>
    </Layout>
  );
};

export default CategoryPage;

export const categoriesQuery = graphql`
  query getAllDishes {
    allContentfulDishes {
      edges {
        node {
          title
          description {
            description
          }
          # heroImage {
          #   file {
          #     url
          #   }
          # }
          price
          slug
          category
        }
      }
    }
    allContentfulImages {
      edges {
        node {
          logo {
            file {
              url
            }
          }
          backgrounds {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
